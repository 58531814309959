<template>
  <div>
    <div class="d-md-flex justify-space-between">
      <h4 class="h4--default mb-4">Sales Report</h4>
      <a
        class="secondary white--text text-capitalize h7--xxsmall v-btn v-btn--has-bg theme--light v-size--default"
        target="_blank"
        :href="exportToExcel"
        >Export to Excel</a
      >
    </div>
    <SalesChart />
  </div>
</template>

<script>
import SalesChart from '@/components/dashboard.vue/SalesChart.vue'

export default {
  components: {
    SalesChart: SalesChart
  },

  mounted() {
    this.setMeta('Dashboard', false)
  },

  computed: {
    exportToExcel() {
      const params = {
        start_date: '2024-09-01',
        end_date: '2024-09-30'
      }

      // Construct the URL for the download
      const baseUrl = `${process.env.VUE_APP_API_URL}/export-transactions`
      const queryString = `?start_date=${encodeURIComponent(
        params.start_date
      )}&end_date=${encodeURIComponent(params.end_date)}`
      const url = `${baseUrl}${queryString}`

      // Return the constructed URL
      console.log('URL for download:', url)
      return url
    }
  }
}
</script>
