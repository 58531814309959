<template>
  <v-dialog v-model="dialog" persistent width="300">
    <div class="white rounded-xl flex-column-center align-center pa-4">
      <ProductCard submitButton :item="item" />

      <div class="d-flex align-center my-4">
        <v-btn @click="handleQuantity('min')" class="success" icon color="white"
          ><v-icon>mdi-minus</v-icon></v-btn
        >
        <p class="h6--xsmall mx-4">{{ qty }}</p>
        <v-btn @click="handleQuantity('add')" class="success" icon color="white"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </div>

      <v-btn
        @click="onSubmit()"
        depressed
        block
        class="secondary h7--xxsmall text-capitalize mb-4"
        small
        >Submit</v-btn
      >
      <v-btn depressed class="gray" small @click="onEmitClose()" block
        >Close</v-btn
      >
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    item: Object,
    state: Object,
    loading: Boolean,
    parentDialog: Boolean
  },

  data: () => ({
    dialog: false,
    qty: 0
  }),

  methods: {
    onEmitClose() {
      this.$emit('on:close')
    },

    onEmitDelete() {
      this.$emit('on:delete')
    },

    handleQuantity(val) {
      if (val === 'min' && this.qty > 0) {
        this.qty -= 1
      }

      if (val === 'add' && this.item.stock > this.qty) {
        this.qty += 1
      }
    },

    async onSubmit() {
      const payload = {
        product_id: this.item.id,
        quantity: this.qty
      }

      const res = await this.$api.transaction.post(payload)

      console.log(res)
    }
  },
  watch: {
    parentDialog(val) {
      this.dialog = val
    }
  }
}
</script>
