import qs from 'query-string'

export default (ctx) => {
  let { $axios } = ctx
  return {
    async login(body) {
      return await $axios.post('/login', body)
    },

    async logout() {
      return await $axios.post('/logout')
    },

    async getProfile() {
      return await $axios.get('/profile')
    },

    async verifyEmail(query = {}) {
      let q = qs.stringify(query)
      return await $axios.get(`/verify-email?${q}`)
    },

    async forgotPassword(email) {
      return await $axios.get(`/password/forgot/${email}`)
    },

    async resetPassword(body) {
      return await $axios.post('password/reset', body)
    },

    async changePassword(id, body) {
      return await $axios.post(`/users/${id}/change-password`, body)
    }
  }
}
