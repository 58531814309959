<template>
  <v-app-bar fixed app style="z-index: 3" class="shadow-base">
    <v-btn depressed @click="onEmitDrawer()"><v-icon>mdi-menu</v-icon></v-btn>
    <v-img
      class="hidden-md-and-up"
      width="80"
      src="/static/bugar-gym-logo-white.png"
    />

    <div class="align-center d-flex full-width">
      <v-icon
        size="18"
        class="hidden-xs-only mr-5 pointer dark--text"
        @click="onGoBack()"
        v-if="meta.child"
      >
        mdi-arrow-left
      </v-icon>
      <v-toolbar-title
        class="hidden-xs-only h4--default text-capitalize dark--text"
      >
        {{ meta.title }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex align-center" v-bind="attrs" v-on="on">
              <v-avatar class="mr-4">
                <img :src="profile.image" alt="avatar" />
              </v-avatar>
              <div>
                <p class="h7--xxsmall">{{ profile.name }}</p>
                <p class="text--small text-capitalize">{{ profile.role }}</p>
              </div>
            </div>
          </template>
          <v-list>
            <v-list-item @click="$router.push(`/users/${profile.id}`)">
              <v-list-item-title class="text--default d-flex align-center">
                <v-icon size="16" class="mr-2">mdi-account</v-icon>
                Profile</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="$router.push(`/change-password`)">
              <v-list-item-title class="text--default d-flex align-center">
                <v-icon size="16" class="mr-2">mdi-lock</v-icon>
                Change Passowrd</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="$store.dispatch('auth/logout')">
              <v-list-item-title
                class="text--default d-flex align-center error--text"
              >
                <v-icon color="error" size="16" class="mr-2">mdi-logout</v-icon>
                Logout
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <!-- <v-btn icon @click.stop="onEmitDrawer()">
        <v-icon>{{ drawer ? 'mdi-close' : 'mdi-menu' }}</v-icon>
      </v-btn> -->
    </div>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    drawer: Boolean
  },
  computed: {
    meta() {
      return this.$store.state.meta
    },
    profile() {
      return this.$store.getters['auth/profile']
    }
  },

  methods: {
    onEmitDrawer() {
      this.$emit('on:show-sidebar')
    },
    onGoBack() {
      this.$router.go(-1)
    }
  }
}
</script>
