<template>
  <div>
    <template v-if="state.isLoading">
      <GeneralLoading />
    </template>

    <template v-if="!state.isLoading">
      <h5 class="h5--small secondary--text my-2">Berita</h5>
      <NewsCard :items="items.news" @on:read="onRead" />

      <h5 class="h5--small secondary--text mb-2 mt-4">Aktifitas Saya</h5>
      <!-- <v-card>
        <ApexChart
          width="100%"
          type="bar"
          :options="options"
          :series="series"
        />
      </v-card> -->

      <ActivityCard :items="items.activites" class="mt-4" />
    </template>
  </div>
</template>

<script>
import ActivityCard from '@/components/card/ActivityCard.vue'
export default {
  components: {
    ActivityCard
  },
  data: () => ({
    state: {
      isLoading: false
    },
    params: {
      news: {
        page: 1,
        per_page: 10,
        keyword: '',
        status: 'active',
        sort: 'created_at'
      }
    },
    paging: {
      news: {}
    },
    items: {
      news: [],
      activites: []
    },
    options: {
      chart: {
        id: 'vuechart-example'
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
      }
    },
    series: [
      {
        name: 'series-1',
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }
    ]
  }),

  created() {
    this.fetch()
  },

  methods: {
    async fetch() {
      const memberId = this.$store.getters['auth/profile'].member.id

      this.state.isLoading = true

      const api = [
        this.$api.news.getList(this.params.news),
        this.$api.dashboard.getActivity({ member_id: memberId })
      ]

      const [resNews, resActivity] = await Promise.all(api)

      this.fetchNews(resNews)
      this.fetchActivity(resActivity)

      this.state.isLoading = false
    },
    fetchNews(res) {
      if (res.success) {
        this.items.news = res.data.list?.map((x, index) => ({
          no: (this.params.page - 1) * this.params.per_page + index + 1,
          ...x
        }))
        this.paging.news = res.data.paging
      }

      if (!res.success) {
        this.setFailedAlert(res.error)
      }

      this.state.isLoading = false
    },

    fetchActivity(res) {
      this.items.activites = res.data

      if (!res.success) {
        this.setFailedAlert(res.error)
      }
    },
    onRead(id) {
      this.$router.push(`/content/${id}`)
    }
  }
}
</script>
