<template>
  <div class="white fill-height pa-6">
    <v-form v-model="state.valid" ref="form">
      <FormTextField
        class="mb-4"
        v-model="currentPassword"
        label="Current Password"
        bold
        outlined
        dense
        hide-details="auto"
        type="password"
        :rules="[required]"
      />

      <FormTextField
        v-model="newPassword"
        class="mb-4"
        label="New Password"
        bold
        outlined
        dense
        hide-details="auto"
        type="password"
        :rules="[required]"
      />

      <FormTextField
        v-model="confirmPassword"
        label="Confirm Password"
        bold
        outlined
        dense
        hide-details="auto"
        type="password"
        :rules="[required]"
      />
    </v-form>
    <v-row class="mt-8">
      <v-col cols="6">
        <v-btn
          :loading="state.isLoading"
          depressed
          class="text-capitalize h7--xxsmall"
          block
          @click="handleDialog()"
          >Cancel</v-btn
        >
      </v-col>
      <v-col cols="6"
        ><v-btn
          :loading="state.isLoading"
          depressed
          class="secondary text-capitalize h7--xxsmall"
          block
          @click="onChangePassowrd()"
          >Save</v-btn
        ></v-col
      >
    </v-row>
  </div>
</template>

<script>
import rules from '@/mixins/rules'
export default {
  mixins: [rules],
  name: 'ProfilePage',
  data: () => ({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    state: {
      isLoading: false,
      valid: true
    }
  }),

  methods: {
    async onChangePassowrd() {
      this.state.isLoading = true

      const valid = await this.validate(this.state.valid)

      if (valid) {
        const payload = {
          current_password: this.currentPassword,
          new_password: this.newPassword,
          new_password_confirmation: this.confirmPassword
        }

        const res = await this.$api.auth.changePassword(
          this.$store.getters['auth/profile'].id,
          payload
        )

        if (res.success) {
          this.setSuccessAlert('Password has been changed')
          this.$router.push('/admin')
        }

        if (!res.success) {
          this.setFailedAlert(res)
        }
      }

      this.state.isLoading = false
    }
  }
}
</script>
