<template>
  <div class="d-flex flex-column align-center justify-center">
    <h4 class="h4--default">Your QR Code</h4>
    <canvas ref="qrcodeCanvas"></canvas>
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  computed: {
    profile() {
      return this.$store.getters['auth/profile']
    }
  },
  mounted() {
    // Generate the QR code after the component is mounted
    this.generateQRCode()
  },
  methods: {
    generateQRCode() {
      const canvas = this.$refs.qrcodeCanvas
      QRCode.toCanvas(
        canvas,
        this.profile['member']['id'],
        {
          width: 300 // Adjust the size of the QR code
        },
        function (error) {
          if (error) console.error(error)
          console.log('QR code generated!')
        }
      )
    }
  }
}
</script>
