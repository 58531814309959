<template>
  <v-app-bar fixed app style="z-index: 3" class="shadow-base">
    <v-img width="80" src="/static/bugar-gym-logo-white.png" />

    <div class="align-center d-flex full-width">
      <v-icon
        size="18"
        class="mr-5 pointer dark--text"
        @click="onGoBack()"
        v-if="meta.child"
      >
        mdi-arrow-left
      </v-icon>
      <v-toolbar-title class="h4--default text-capitalize dark--text">
        {{ meta.title }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-btn icon @click.stop="onEmitDrawer()">
        <v-icon>{{ drawer ? 'mdi-close' : 'mdi-menu' }}</v-icon>
      </v-btn> -->
    </div>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    drawer: Boolean
  },
  computed: {
    meta() {
      return this.$store.state.meta
    }
  },

  methods: {
    onEmitDrawer() {
      this.$emit('on:drawer')
    },
    onGoBack() {
      this.$router.go(-1)
    }
  }
}
</script>
