<template>
  <div>
    <h5 class="h5--small grey--text mb-2">{{ month }}</h5>
    <v-sheet height="100%">
      <v-calendar
        ref="calendar"
        v-model="value"
        type="month"
        :events="items"
        :event-overlap-mode="mode"
        :event-overlap-threshold="60"
      ></v-calendar>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    items: Array
  },
  data: () => ({
    mode: 'stack',
    value: '',
    month: ''
  }),

  created() {
    const date = new Date()
    this.month = this.$dayjs(date).format('MMMM YYYY')
  },

  methods: {}
}
</script>
