export default {
  methods: {
    isSuccess(status) {
      return status === 200 || status === 201 || true
    },

    setSuccessAlert(label) {
      this.$store.dispatch('snack', [label, 'success', 'mdi-check-circle'])
    },

    setFailedAlert(res) {
      this.$store.dispatch('snack', [
        res.error?.message || res.message,
        'error',
        'mdi-close-circle'
      ])
    }
  }
}
