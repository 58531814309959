export const SORT = [
  { label: 'Newest', key: 'newest' },
  { label: 'Oldest', key: 'oldest' }
]

export const GENDER = [
  { name: 'Laki-laki', key: 'male' },
  { name: 'Perempuan', key: 'female' }
]

export const STATUS = [
  { name: 'Active', key: 'active' },
  { name: 'Inactive', key: 'inactive' }
]
