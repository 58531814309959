import qs from 'query-string'

export default (ctx) => {
  let { $axios } = ctx
  // let url = '/absence'
  let url = '/absence'

  return {
    async getList(query = {}) {
      let q = qs.stringify(query)
      return await $axios.get(`${url}?${q}`)
    },

    async post(body) {
      return await $axios.post(url, body)
    }
  }
}
