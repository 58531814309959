<template>
  <div class="shadow-base pa-6 rounded-xl">
    <template v-if="state.isLoading">
      <GeneralLoading />
    </template>
    <GeneralDateRange
      :parentDate="dates"
      :parentMenu="menu"
      getSevenDays
      @get:date="getDate"
      @on:fetch="fetchData()"
    />
    <template v-if="!state.isLoading">
      <div id="chart">
        <apexchart
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
        />
      </div>
      <p class="text-center">{{ state.total_revenue }}</p>
    </template>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      menu: false,
      dates: [], // Default date range: 7 days ago to today
      state: {
        isLoading: false,
        total_revenue: 0
      },
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: false,
          stackType: '100%'
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
          }
        ],
        xaxis: {
          categories: []
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'right',
          offsetX: 0,
          offsetY: 50
        }
      }
    }
  },
  mounted() {
    this.fetchData()
  },

  methods: {
    getDate(val) {
      this.dates = [val[0], val[1]]
    },

    async fetchData() {
      this.state.isLoading = true

      const params = {
        start_date: this.dates[0],
        end_date: this.dates[1]
      }

      const res = await this.$api.dashboard.get(params)

      const { series, categories, total_revenue } = res.data

      this.series = series
      this.chartOptions.xaxis.categories = categories
      this.state.total_revenue = total_revenue

      this.state.isLoading = false
    }
  }
}
</script>

<style>
/* Add your styles here */
</style>
