<template>
  <v-dialog v-model="dialog" persistent width="600">
    <div class="white rounded-xl flex-column-center align-center py-10">
      <v-icon size="100" color="error lighten-1">mdi-delete</v-icon>
      <h4 class="h4--default text-center dark--text text--lighten-1">
        Apakah Anda yakin ingin menghapus data ini?
      </h4>

      <div class="d-flex mt-6">
        <v-btn
          depressed
          outlined
          class="mr-2 text-capitalize h7--xxsmall"
          :disabled="loading"
          @click="onEmitClose()"
          >Batal</v-btn
        >
        <v-btn
          depressed
          class="error lighten-1 ml-sm-2 text-capitalize h7--xxsmall"
          :loading="loading"
          @click="onEmitDelete()"
          >Hapus</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    state: Object,
    loading: Boolean,
    parentDialog: Boolean
  },

  data: () => ({
    dialog: false
  }),

  methods: {
    onEmitClose() {
      this.$emit('on:close')
    },

    onEmitDelete() {
      this.$emit('on:delete')
    }
  },
  watch: {
    parentDialog(val) {
      this.dialog = val
    }
  }
}
</script>
