import qs from 'query-string'

export default (ctx) => {
  let { $axios } = ctx
  let url = '/transactions'
  return {
    async getList(query = {}) {
      let q = qs.stringify(query)
      return await $axios.get(`${url}?${q}`)
    },

    async getOne(id) {
      return await $axios.get(`${url}/${id}`)
    },

    async post(body) {
      return await $axios.post(url, body)
    },

    async put(id, body) {
      console.log('body put', body)
      return await $axios.post(`${url}/${id}`, body)
    },

    async delete(id) {
      return await $axios.delete(`${url}/${id}`)
    }
  }
}
