import qs from 'query-string'

export default (ctx) => {
  let { $axios } = ctx
  let url = '/dashboard'
  // let url = 'http://127.0.0.1:8000/api/absence'
  // 'https://fb11-2001-448a-2095-13fc-ec95-6d85-9432-d43e.ngrok-free.app/api/absence'

  return {
    async get(query = {}) {
      let q = qs.stringify(query)
      return await $axios.get(`${url}?${q}`)
    },

    async export(query = {}) {
      let q = qs.stringify(query)
      return await $axios.get(`export-transactions?${q}`)
    },

    async getActivity(id) {
      // let q = qs.stringify(query)
      return await $axios.post('myActivity', id)
    }
  }
}
