<template>
  <div>
    <GeneralTableHeader
      @on:search="onSearch"
      @on:sort="onSort"
      actionCreate="/users/create"
    />

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="state.isLoading"
      hide-default-footer
      no-data-text="No Data"
      class="mt-8"
    >
      <template v-slot:[`item.image`]="{ item }">
        <v-avatar class="my-2">
          <img :src="item.image" alt="avatar" />
        </v-avatar>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex align-center">
          <v-btn
            x-small
            icon
            class="primary--text h7--xxsmall mr-4"
            @click="onDetail(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="$store.getters['auth/profile'].id !== item.id"
            x-small
            icon
            class="error--text h7--xxsmall"
            @click="handleDialogDelete(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <GeneralDialogDelete
      @on:dialog="handleDialogDelete"
      @on:delete="onDelete"
      @on:close="handleDialogDelete"
      :loading="state.isLoading"
      :parentDialog="state.dialogDelete"
    />

    <GeneralPagination
      class="mt-8"
      :paging="paging"
      @on:change="onChangePagination"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: 'HomeView',
  data() {
    return {
      headers: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2',
          width: 10
        },
        {
          text: 'Photo',
          value: 'image',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Email',
          value: 'email',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Phone',
          value: 'phone',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          align: 'center',
          class: 'dark--text h7--xxsmall dark lighten-2',
          width: 50
        }
      ],
      data: null,
      search: '',
      selectedType: 'SEMUA',
      params: {
        page: 1,
        per_page: 10,
        keyword: '',
        sort: 'newest',
        role: 'admin'
      },
      paging: {},
      state: {
        isLoading: false,
        selectedItem: {},
        dialogDelete: false
      },
      items: [],
      itemsType: ['SEMUA', 'Active', 'Inactive']
    }
  },

  created() {
    this.fetch()
  },

  mounted() {
    this.setMeta('User')
  },

  methods: {
    async fetch() {
      this.state.isLoading = true
      const res = await this.$api.user.getList(this.params)

      if (res.success) {
        this.items = res.data.list?.map((x, index) => ({
          no: (this.params.page - 1) * this.params.per_page + index + 1,
          ...x
        }))
        this.paging = res.data.paging
      }

      if (!res.success) {
        this.setFailedAlert(res.error)
      }

      this.state.isLoading = false
    },

    onSearch: debounce(function (val) {
      this.params.keyword = val
      this.params.page = 1
      this.fetch()
    }, 500),

    onChangePagination(val) {
      this.params.page = val
      this.fetch()
    },

    onSort(val) {
      this.params.sort = val
      this.params.page = 1
      this.fetch()
    },

    onDetail(val) {
      this.$router.push(`/users/${val.id}`)
      //
    },

    handleDialogDelete(val) {
      if (val?.id) {
        this.state.selectedItem = val
      }
      this.state.dialogDelete = !this.state.dialogDelete
    },

    async onDelete() {
      try {
        this.state.isLoading = true
        this.$api.user.delete(this.state.selectedItem?.id)
        this.fetch()
      } catch (error) {
        this.setFailedAlert(error)
      }
      this.state.dialogDelete = false
    }
  }
}
</script>
