<template>
  <div>
    <template v-if="state.isLoading">
      <GeneralLoading />
    </template>

    <template v-if="!state.isLoading">
      <v-btn
        depressed
        class="text-capitalize h7--xxsmall my-4"
        @click="$router.push('/home')"
      >
        <v-icon class="mr-2" size="16">mdi-arrow-left</v-icon> Back</v-btn
      >
      <h2 class="h2--xlarge">{{ item.title }}</h2>
      <v-img class="rounded-xl my-10" :src="item.image" />
      <div class="full-height v-responsive">
        <p v-html="item.content"></p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    item: {},
    state: { isLoading: false },
    id: null
  }),

  created() {
    this.id = this.$route.params.slug
    this.fetch()
  },

  methods: {
    async fetch() {
      this.state.isLoading = true

      const res = await this.$api.news.getOne(this.id)

      if (res.success) {
        this.item = res.data
      }

      if (!res.success) {
        this.setFailedAlert(res)
      }
      this.state.isLoading = false
    }
  }
}
</script>
