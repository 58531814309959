<template>
  <div>
    <v-navigation-drawer v-model="drawer" app class="drawer">
      <div class="d-flex justify-center">
        <img
          width="120"
          class="mt-4"
          src="/static/bugar-gym-logo-white.png"
          alt="logo"
        />
      </div>
      <div class="mt-8">
        <p class="h8--supersmall white--text ml-5">Menu</p>
        <v-list>
          <div v-for="(item, i) in menu" :key="i">
            <v-list-item
              class="pointer"
              link
              :to="onRedirect(item.path)"
              active-class="v-list-item--active"
            >
              <v-icon class="white--text mr-6">{{ item.icon }}</v-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text h7--xxsmall">
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </div>
    </v-navigation-drawer>

    <GeneralAppBar @on:show-sidebar="onShowSidebar" />
  </div>
</template>

<script>
import { MENU } from '@/data/menu'
// import GeneralSideBar from '../../components/drawer/GeneralSidebar.vue'
export default {
  components: {
    // GeneralSideBar
  },
  data: () => ({
    menu: MENU,
    drawer: true,
    path: ''
  }),

  created() {
    this.activeRoute = this.$route?.path
  },

  computed: {
    profile() {
      return this.$store.getters['auth/profile']
    }
  },

  methods: {
    onRedirect(path) {
      // console.log(path)
      return path
    },

    onShowSidebar() {
      this.drawer = true
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.path = val.name
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer {
  // background: radial-gradient(
  //   235.64% 67.4% at 135% 83.72%,
  //   #2111a1 0%,
  //   #3890d9 100%
  // );
  background: rgb(49, 49, 49);
  box-shadow: 0px 12px 26px rgba(0, 69, 172, 0.14);
}

.bottom-logo {
  position: absolute;
  bottom: 0;
  z-index: -1;
}

.v-list-item {
  border-left: 2px solid transparent;
  z-index: 1;
  &--active {
    background: var(--v-secondary-base);
    border-left: 2px solid var(--v-secondary-lighten5);

    &_child {
      background: transparent !important;

      &::after {
        content: '';
        width: 8px;
        min-height: 8px;
        position: absolute;
        border-radius: 100%;
        background-color: var(--v-secondary-lighten5);
        left: 46px;
      }
    }
  }
}

::v-deep .v-list-item__icon {
  color: white;
}

::v-deep .mdi-chevron-down {
  color: white;
}
</style>
