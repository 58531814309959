<template>
  <component :is="layoutComponent">
    {{ layoutComponent }}
    <router-view />
  </component>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import FullScreenLayout from '@/layouts/FullScreenLayout.vue'
import AdminLayout from './layouts/AdminLayout.vue'

export default {
  name: 'App',
  data() {
    return {
      drawer: false
    }
  },
  computed: {
    layoutComponent() {
      const layout = this.$route.meta.layout || 'DefaultLayout'

      if (layout === 'FullScreenLayout') return FullScreenLayout
      if (layout === 'AdminLayout') return AdminLayout

      return DefaultLayout
    }
  },
  methods: {
    onSetDrawer() {
      this.drawer = !this.drawer
    }
  }
}
</script>

<style scoped>
.o-app {
  padding: 16px !important;
  margin-top: 84px;
}
/* Add any global styles here */
</style>
